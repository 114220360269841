import React, { useState, useEffect, useContext } from 'react';
import { List, ListItem, ListItemText, Divider, IconButton, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import DeleteIcon from '@mui/icons-material/Delete';
import { TopicContext } from './TopicContext';

function NotesList() {
  const { topic } = useContext(TopicContext);
  const [notes, setNotes] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('*');
  const [currentAudio, setCurrentAudio] = useState({ audio: null, playingNoteId: null });

  useEffect(() => {
    fetchNotes();
    fetchTags();
  }, []);

  const fetchNotes = () => {
    fetch(`https://5lns77706c.execute-api.us-east-1.amazonaws.com/dev/notes?topic=${encodeURIComponent(topic)}`)
      .then(response => response.json())
      .then(data => {
        console.log('notes data: ' + JSON.stringify(data)); // Log the data to the console
        setNotes(data.Items);
      })
      .catch(error => console.error('Error fetching notes:', error));
  };

  const fetchTags = () => {
    // Assuming the API now uses query parameters or headers for user identification
    fetch(`https://5lns77706c.execute-api.us-east-1.amazonaws.com/dev/tags?topic=${encodeURIComponent(topic)}`)
      .then(response => response.json())
      .then(data => {
        console.log('data: ' + JSON.stringify(data)); // Log the data to the console
        //const tagsArray = data.data.map(item => item.tag);
        const tagsArray = data.map(item => item.tag);
        console.log(tagsArray);
        setTags(tagsArray);
      })
      .catch(error => console.error('Error fetching tags:', error));
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
  };

  const filteredNotes = selectedTag === '*' ? notes : notes.filter(note => note.tag === selectedTag);

  const handleDeleteNote = (noteId) => {
    fetch(`https://5lns77706c.execute-api.us-east-1.amazonaws.com/dev/notes/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ note_id: noteId }),
    })
      .then(() => {
        // Successfully deleted from backend, now remove from local state
        const updatedNotes = notes.filter(note => note.note_id !== noteId);
        setNotes(updatedNotes);
      })
      .catch(error => console.error('Error deleting note:', error));
  };

  const handlePlayAudio = (noteId, url) => {
    if (currentAudio.playingNoteId === noteId) {
      currentAudio.audio.pause();
      currentAudio.audio.currentTime = 0; // Reset the audio to the start.
      setCurrentAudio({ audio: null, playingNoteId: null });
      return;
    }

    if (currentAudio.audio) {
      currentAudio.audio.pause();
      currentAudio.audio.currentTime = 0;
    }

    const audio = new Audio(url);
    audio.play().catch(error => console.error('Error playing audio:', error));
    setCurrentAudio({ audio, playingNoteId: noteId });

    audio.onended = () => {
      setCurrentAudio({ audio: null, playingNoteId: null });
    };
  };

  return (
    <>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Notes for {topic}
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel id="filter-by-tag-label">Filter by Tag</InputLabel>
        <Select
          labelId="filter-by-tag-label"
          value={selectedTag}
          label="Filter by Tag"
          onChange={handleTagChange}
        >
          <MenuItem value="*">All</MenuItem>
          {Array.isArray(tags) && tags.map((tag, index) => (
            <MenuItem key={index} value={tag}>
              {tag}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Display the count of list items here */}
      <Typography variant="body1" style={{ marginTop: '10px' }}>
        Notes Count: {filteredNotes.length}
      </Typography>
      <List>
        {filteredNotes.map(note => (
          <React.Fragment key={note.note_id}>
            <ListItem alignItems="flex-start">
              <IconButton edge="start" aria-label={currentAudio.playingNoteId === note.note_id ? "stop" : "play"} onClick={() => handlePlayAudio(note.note_id, note.url)} style={{ color: 'blue', marginRight: '20px' }}>
                {currentAudio.playingNoteId === note.note_id ? <StopIcon /> : <PlayArrowIcon />}
              </IconButton>
              <ListItemText
                primary={note.tag}
                secondary={
                  <>
                    <div>{note.text.substring(0, 50)}</div>
                    <div>{`Voice: ${note.voice}`}</div>
                  </>
                }
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteNote(note.note_id)} style={{ color: 'orange' }}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
    </>
  );
}

export default NotesList;
