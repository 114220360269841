import React, { useContext } from 'react';
import NoteForm from './NoteForm';
import NotesList from './NotesList';
import NoteDetails from './NoteDetails';
import { AppBar, Toolbar, Typography, Button, Box, Stack } from '@mui/material';
import { BrowserRouter, Routes, Route, Link, useNavigate } from 'react-router-dom';
import { TopicProvider, TopicContext } from './TopicContext';

function MainApp() {
  const { topic, setTopic } = useContext(TopicContext);
  const navigate = useNavigate();
  const handleNotebookClick = (notebookName) => {
    setTopic(notebookName); // Update the context
    navigate('/list'); // Navigate to NotesList.js
  };

  const DefaultView = () => (
    <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Notebooks
      </Typography>
      <Stack spacing={2} width="100%" alignItems="center">
        <Button
          variant="contained"
          onClick={() => handleNotebookClick('AWS CDA 2024')}
          sx={{ width: '200px' }} // Optional: to have uniform button width
        >
          AWS CDA 2024
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNotebookClick('AWS SAP-C02')}
          sx={{ width: '200px' }} // Optional: to have uniform button width
        >
          AWS SAP-C02
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNotebookClick('AWS AIF-C01')}
          sx={{ width: '200px' }} // Optional: to have uniform button width
        >
          AWS AIF-C01
        </Button>
      </Stack>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            MyNotes
          </Typography>
          <Button color="inherit" component={Link} to="/topics">
            Topics
          </Button>
          <Button color="inherit" component={Link} to="/form" disabled={!topic}>
            Enter Notes
          </Button>
          <Button color="inherit" component={Link} to="/list" disabled={!topic}>
            Read Notes
          </Button>
        </Toolbar>
      </AppBar>

      <Box sx={{ p: 3 }}>
        <Routes>
          <Route path="/topics" element={<DefaultView />} />
          <Route path="/form" element={<NoteForm />} />
          <Route path="/list" element={<NotesList />} />
          <Route path="/note/:noteId" element={<NoteDetails />} />
          <Route path="*" element={<DefaultView />} />
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <BrowserRouter>
      <TopicProvider>
        <MainApp />
      </TopicProvider>
    </BrowserRouter>
  );
}

export default App;
