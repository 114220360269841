import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography } from '@mui/material';

function NoteDetails() {
  const { noteId } = useParams();
  const [note, setNote] = useState(null);

  useEffect(() => {
    // Fetch note details using `noteId`
    // Placeholder fetch function, replace URL with your actual endpoint
    fetch(`https://your-api-endpoint/notes/${noteId}`)
      .then(response => response.json())
      .then(data => setNote(data))
      .catch(error => console.error('Error fetching note:', error));
  }, [noteId]);

  return (
    <div>
      <IconButton component={Link} to="/" edge="start" aria-label="back" style={{ marginBottom: '20px' }}>
        <ArrowBackIcon />
      </IconButton>
      {note ? (
        <>
          <Typography variant="h5">{note.tag}</Typography>
          <Typography variant="body1">{note.text}</Typography>
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
}

export default NoteDetails;
