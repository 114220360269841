import React, { useState, useContext } from 'react';
import { TextField, Button, Box, FormControl,  Typography, InputLabel, Select, MenuItem, Snackbar, Alert } from '@mui/material';
import { TopicContext } from './TopicContext';

function NoteForm() {
  const { topic } = useContext(TopicContext);

  const [note, setNote] = useState({
    userId: 'todd.kegley@gmail.com',
    topic: topic,
    voice: 'Joey',
    noteBody: '',
    tag: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const [isSubmitting, setIsSubmitting] = useState(false); // Step 1: State variable for submitting

  const handleChange = (e) => {
    setNote({ ...note, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Step 2: Set to true when submitting
    fetch('https://5lns77706c.execute-api.us-east-1.amazonaws.com/dev/notes', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(note),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      console.log('Success:', data);
      setSnackbarMessage('Note submitted successfully!');
      setSeverity('success');
      // Clear the form
      setNote({
        userId: 'todd.kegley@gmail.com',
        topic: topic,
        noteBody: '',
        tag: '',
        voice: 'Joey'
      });
    })
    .catch((error) => {
      console.error('Error:', error);
      setSnackbarMessage('Failed to submit note. Please try again.');
      setSeverity('error');
    })
    .finally(() => {
      setIsSubmitting(false); // Step 2: Set back to false when submission is done
      setOpenSnackbar(true);
    });
  };

  const voices = ['Amy', 'Emma', 'Brian', 'Ivy',  'Kendra', 'Kimberly','Joey', 'Justin'];
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
       <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        {topic}
      </Typography>
      <TextField label="User ID" name="userId" value={note.userId} onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Notes" name="noteBody" multiline rows={8} inputProps={{ maxLength: 5000}} value={note.noteBody} required onChange={handleChange} fullWidth margin="normal" />
      <TextField label="Tag" name="tag" value={note.tag} onChange={handleChange} fullWidth margin="normal"  required/>
      <FormControl fullWidth margin="normal">
        <InputLabel id="voice-label">Voice</InputLabel>
        <Select
          labelId="voice-label"
          id="voice"
          name="voice"
          value={note.voice}
          label="Voice"
          onChange={handleChange}
        >
          {voices.map((voice) => (
            <MenuItem key={voice} value={voice}>
              {voice}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}> {/* Step 3: Disable button when submitting */}
        {isSubmitting ? 'Submitting...' : 'Submit'} {/* Change button text */}
      </Button>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={severity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default NoteForm;
